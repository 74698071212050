<template lang="">
    <div>
        <List />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'İş Ortakları Çalışanlar Listesi', route: '/is-ortaklari-calisanlar' },
        ]);
    },
    components:{
        List: () => import("@/components/isortaklari/Calisanisortaklari")
    }
}
</script>
<style lang="">
    
</style>